.new-req{
    justify-content: end;
    display: flex;
    margin-top: 1.5rem;
    margin-right: 12px;
}
.new-req a{
    color: white;
    background-color: #00094b;
    padding: 0.5rem;
    border-radius: 10px;
    text-decoration: none;
    border: 10px solid #00094b;
    margin-top: 4rem;
}
.about-merge {
    padding: 10px;
    border: 3px solid black;
    border-radius: 10px;
    margin: 10px;
    font-weight: bold;
}