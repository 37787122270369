.merge-screen-container{

    margin-left: 10%;
    margin-right: 10%;

    margin-top: 5%;

    margin-bottom: 5%;

}


.merge-screen-stepper{
   

    background-color: #fbfafa;

    border-radius: 2%;
    
   
 
    padding: 2%; 
}

.merge-screen-header{

    text-align: center;
  

}