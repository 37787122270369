.supplier-map-plot {
    border: 1px solid lightgray;
  }

  .map-component-section{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .map-component-reset-zoom-button{
    color: #00094b !important;
    font-weight: bold !important;
    border-color: #00094b !important;
  }
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  }
  .supplier-map-component-filter-container {
    width: 100%;
    /* background-color: #f5f8f9; */
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .map-component-container {
    width: 100%;
  }
  .supplier-filter-map-container-suspicious-filter {
    background-color: #FFF;
    display: flex;
    width: fit-content;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem;
    height: fit-content;
    /* margin-left: auto; */
  }
  .tooltip-info{
    padding: 2rem;
    font-weight: bold;
  }

@media (max-width: 600px) {
  .sankey-data-container {
    display: none;
  }
}