.feedback-page-container{
    color: #000000;
    
    display: flex;

    flex-wrap: wrap;
    /* align-content: center; */
    /* justify-content: center; */
 
    /* width: 100%; */

    background-color: #fbfafa;

    margin: 8rem;
    padding: 2rem;

    justify-content: center;

    row-gap: 2rem;
    flex-direction: column;
    
    border: 1px solid #f6f6f6;
    border-radius: 1rem;
}

.feedback-page-heading{
    text-align: center;
    justify-content: center;
}

.feedback-page-category{
    display: flex;
    justify-content: center;
}

.feedback-page-text-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.feedback-page-text-box{
    width: 50%;
}

.feedback-page-submit-button{
    width: 50%;
}

.feedback-page-submit-container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.feedback-page-validation-error-container{
    color: red;
    margin-bottom: 10px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    width: 100%;

}

.feedback-page-validation-error-text{
   width: 50%;
   

}

.feedback-page-validation-success-container{
    color: Green;
    margin-bottom: 10px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    width: 100%;

}

.feedback-page-validation-success-text{
   width: 50%;
   

}