.top-nav {
  background-color: var(--color-primary) !important;
  color: var(--color-white);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
}

.logo-style img {
  max-width: 220px;
  max-height: 60px;
  width: 100%;
  max-height: 60px;
  padding: 5px;
}

.navbar .navbar-brand,
.navbar .nav-link,
.navbar .navbar-toggler-icon,
.navbar .dropdown-toggle {
  color: #fff !important;
}

.nav-container {
  background-color: rgb(0, 98, 178) !important;
  color: #fff !important;
  width: 100%;
  display: flex;
  font-size: 1.2rem;
  max-height: 53px;
}

.navbar-brand{
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-nav{
  gap:1rem;
  flex-wrap: wrap;
}

.color-red {
  color: var(--color-red);
}

.custom-toggler {
  border: none;
  background: none;
}

.custom-toggler .navbar-toggler-icon {
  background-image: none;
  width: 24px;
  height: 18px;
  position: relative;
}

.centred {
  text-align: left;
  width: 50%;
}

.custom-toggler .navbar-toggler-icon::before,
.custom-toggler .navbar-toggler-icon::after,
.custom-toggler .navbar-toggler-icon div {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  margin: 0%;
  background-color: #fff;
  position: absolute;
  transition: all 0.3s;
}

.custom-toggler .navbar-toggler-icon::before {
  top: 0;
}

.custom-toggler .navbar-toggler-icon::after {
  bottom: 0;
}

.custom-toggler .navbar-toggler-icon div {
  top: 50%;
  transform: translateY(-50%);
}

.custom-toggler.cross-icon .navbar-toggler-icon::before {
  transform: rotate(45deg);
  top: 50%;
}

.custom-toggler.cross-icon .navbar-toggler-icon::after {
  transform: rotate(-45deg);
  bottom: 50%;
}

.custom-toggler.cross-icon .navbar-toggler-icon div {
  transform: scale(0);
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar-nav {
    gap: 0.5rem;
  }
  .nav-link {
    font-size: 1rem;
  }
  .logo-style img {
    max-width: 180px;
  }
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 100%;
  }

  .nav-container-expanded {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  .custom-toggler {
    width: 10%;
    text-align: center;
    margin-left: auto;
  }

  button.custom-toggler.cross-icon {
    float: right;
    margin-left: auto;
    width: 10%;
    right: 0;
  }
}