.wd-login-item {
  width: 100%;
}

.wd-submit-btn-login {
   text-align: center;
   height: 60px;
   width: 100%;
   margin: 20px 0;
}

.gradient-custom-1 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
} 

.gradient-custom-2 {
       /* fallback for old browsers */
       /* background: #6a11cb; */
  
       /* Chrome 10-25, Safari 5.1-6 */

       /* background: -webkit-linear-gradient(to bottom, rgba(106,17,203,1), rgba(0,50,138,1)); */
     
       /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
       /* background: linear-gradient(to bottom, rgba(106,17,203,1), rgba(0,50,138,1)) */

       /* fallback for old browsers */
    background: #0062b2;
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to top, rgba(0,98,178,1), rgba(248,25,65,1));
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to top, rgba(0,98,178,1), rgba(248,25,65,1))

    
       /* background: #2f6bb3; */
  
       /* Chrome 10-25, Safari 5.1-6 */
       /* background: -webkit-linear-gradient(to top, rgba(47,107,179,1), rgba(223,98,59,1)); */
     
       /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
       /* background: linear-gradient(to top, rgba(47,107,179,1), rgba(223,98,59,1)) */
  }

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}

.login-full-width{
    width: 100%;
}

.login-header {
  margin-bottom: 2rem;
  text-align: center;
}

.login-page-logo {
  width: 300px;
}

.login-page-logo-tagline{
  padding-left: 5rem;
  font-size: 0.7rem;
  margin-top: -1.5rem;
  color: var(--color-black);
}

.login-window {
  margin-left: auto;
  margin-right: auto;
  padding-left: 35%;
  padding-right: 35%;
  padding-top: 1.5%;
  height: 100%;
}

.MuiTab-root:hover {
  color: #1876D2;
  opacity: 1;
  background-color:white;
}

@media (min-width: 600px) and (max-width: 991px) {
  .login-window {
    padding-left: 30%;
    padding-right: 30%;
  }
}

@media (max-width: 600px) {
  .login-window {
    padding-left: 15%;
    padding-right: 15%;
  }

  .login-page-logo-tagline {
    margin: 0%;
    padding: 0%;
    text-align: center;
  }

  .login-header {
    margin: 0%;
    padding: 0%;
    text-align: center;
  }

  .login-page-logo {
    display: block;
    margin: 0 auto;
  }
}