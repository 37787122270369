.about-page-text-container{
    /* margin: 9rem; */
    color: #000000;
    
    display: flex;

    flex-wrap: wrap;
    /* align-content: center; */
    /* justify-content: center; */
 
    /* width: 100%; */

    
    border: 1px solid #f6f6f6;
    border-radius: 1rem;
   

}

.about-page-quote-name{
    text-align: end;
}

.about-page-contributor{
    width: 20%;
    text-align: center;
}

.about-page-contributors-container{
    display: flex;
    flex-wrap: wrap;
}

.about-page-contributor-info{
    text-align: center;
}

.about-page-mission-container{
    background-color: #fbfafa;
    padding: 5rem;
    display: flex;
    font-family: Darker Grotesque,sans-serif;

}

.about-page-mission-heading{
    font-size: 4rem;
    font-weight: bold;
}

.about-page-mission-text{
    color: #010204;
    font-size: 1.5rem;
    line-height: 1.16667;
    /* width: 50%; */
    text-align: justify;
    
}

.about-page-mission-content{
    min-width: 50%;
}

.about-page-mission-image{
    width: 50%;
    padding: 2rem;
}


.about-page-datahub-container{
    background-color: #fbfafa;
    padding: 5rem;
    display: flex;
    font-family: Darker Grotesque,sans-serif;

}

.about-page-context-image{
    padding: 2rem;
}

.about-page-datahub-heading{
    font-size: 4rem;
    font-weight: bold;
}

.about-page-datahub-text{
    color: #010204;
    font-size: 1.5rem;
    line-height: 1.16667;
    /* width: 50%; */
    text-align: justify;
    
}

.about-page-datahub-content{
    min-width: 50%;
}

.about-page-datahub-image{
    width: 50%;
    padding: 2rem;
}




.about-page-context-container{
    margin: 2rem;
    padding: 5rem;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-family: Darker Grotesque,sans-serif;

}

.about-page-context-heading{
    font-size: 4rem;
    font-weight: bold;
}

.about-page-context-text{
    color: #010204;
    font-size: 1.5rem;
    line-height: 1.16667;
    text-align: justify;
    
}


.about-page-context-image{
    min-width: 50%;
}


.about-page-team-container{
    padding: 5rem;
}