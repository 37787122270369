.about-component-container {
    background-color: #F5F5F5;
    border-radius: 2rem;
    padding: 1rem 3rem;
    margin-left: 0rem;
}
.evidences-container {
    margin: 1rem;
    margin-left: 0rem;
    padding: 2rem 2rem 4rem 2rem;
    background-color: rgb(71, 73, 115, 0.05);
    border-radius: 1rem;
}
.about-component-header {
    font-size: 2rem;
    font-weight: bold;
    color: #00094b;
}

.about-charts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.about-chart-container {
    /* background-color: #FFF; */
    padding: 2rem;
    margin: 1rem 1rem 1rem 0;
    width: 45%;
}

.about-chart-line-chart {
    width: 100% !important;
}
.risk-indicator-container {
    margin-top: 1rem;
    background-color: #f1b0b0; 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
.risk-indicator-container p {
    font-size: 18px;
    font-weight: bolder;
    color: #0b0b0b;
    margin-bottom: 10px;
}

@media (max-width: 1100px) {
    .about-chart-container {
        width: 100% !important;
    }
}
.about-evidence{
    width: 100% ;
}

.shu_info{
    padding-bottom: 1rem;
    font-weight: bold;
}
.scrollable-container {
    max-height: 200px;
    overflow: scroll;
}
  
  /* Optional: Style the scrollbar */
.scrollable-container::-webkit-scrollbar {
    width: 8px;
}
  
.scrollable-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}
  
.scrollable-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}