:root {
    --map-list-width: 70%;
    --border-color: #ccc;
    --bg-color: #006EBE;
  }
.suspicious_map {
    height: 750px;
    width: 95%;
}
.risk-spotlight-container{
    width: 100%;
}
.risk-spotlight-button{
    margin-bottom: 2rem;
    justify-content: end;
    display: flex;
    margin-right: 3.5rem;
}

.risk-spotlight-reset-button{
    color: black !important;
    border-color: white !important;
    font-weight: bolder !important;
}
.map-and-list-container {
    display: flex;
    justify-content: space-around;
    width:  var(--map-list-width);
    background-color:  var(--bg-color);
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 750px; 
  }
  .suspicious-list {
    width: 50%; /* Adjust as needed */
    height: 700px;
    overflow-y: auto; /* Enable scrolling if the list is longer than the container */
    margin-right: 1rem;
    padding-right: 1rem;
  }
  
  .suspicious-list h3 {
    text-align: center;
  }
  
  .suspicious-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .suspicious-list li {
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
  .suspicious-cell {
    color: black;
    background-color: white;
    padding: 1rem;
  }
  .suspicious-cell-address {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
  .suspicious-cell-address-text {
    padding-left: 10px;
  }
  .learn-more {
    padding-left: auto;
    display: flex;
    align-items: center;
    color: red;
    justify-content: flex-end;
  }

  @media (max-width: 600px) {
    .map-and-list-container {
      margin: 0%;
      padding: 0%;
      flex-direction: column;
      height: auto; /* Adjust the height for mobile view */
    }
    .suspicious_map {
      height: 330px;
      width: 100%;
  }
    .suspicious-list {
      padding: 3% 0% 1% 3%;
      width: 95%;
      padding-right: 0%;
    }
    .suspicious-cell {
      padding: 1%;
    }
  }