.summary-container {
  position: relative;
  background-color: #ECF1F2; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.summary-container p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.ml-disclaimer-tooltip.css-mf1cb5-MuiButtonBase-root-MuiIconButton-root {
  position: absolute;
  top: 10px; 
  right: 10px; 
  cursor: pointer;
}