/* RequestDetails.css */

/* Style for the container */
.requestDetailContainer {
    max-width: 800px;
    margin: 5rem auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .details a {
    font-weight: bold;
    text-decoration: none;
    color: black;
    font-size: medium;
  }
  
  /* Style for the heading */
  h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Style for the details section */
  .details {
    margin-bottom: 20px;
  }
  
  /* Style for the list */
  ul {
    list-style: none;
    padding: 0;
  }
  
  /* Style for list items */
  li {
    margin-bottom: 5px;
  }
  
  /* Style for the button */
  button {
    background-color: #00094b;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Style for the button on hover */
  button:hover {
    background-color: #00094b;
  }
  .details {
    margin-bottom: 20px;
  }
  
  /* Style for the details section */
  .details p {
    display: flex;

    /* font-weight: bold; */
  }
  
  /* Style for the last child of details to remove the border-bottom */
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .target-company-container {
    border: 2px solid #00094b;
    border-radius: 1%;

  }

  .info-section .value{
    padding-left: 10px;
  }
  .info-section .label{
    font-weight: bold;
    padding-right: 10px;
  }