.year-drop-down{
    padding-left: 1rem;
  }

  .apply-years-btn{
    margin-left: 1rem !important;
    font-weight: bold  !important;
    border-width: 2px !important;
    min-width: 100px !important;
  }

  .apply-years-btn:disabled{
    margin-left: 1rem !important;
    background-color: #C0C0C0 !important;
    color: #696969 !important;
    font-weight: bold  !important;
    border: none !important;
    min-width: 100px !important;
  }