body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Common CSS start */

/* TO DO : refactor all common CSS to pick from this list */
:root {
  /* Font Sizes */
  --font-size-small: 0.85rem;
  --font-size-body: 1rem;
  --font-size-big: 1.2rem;
  --font-size-bigger: 1.5rem;
  --font-size-biggest: 3rem;

  /* Font Weights */
  --font-weight-lighter: 300;
  --font-weight-light: 400;
  --font-weight-regular: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  /* Colors */
  --color-primary: rgb(0, 98, 178);
  --color-secondary: #6c757d;
  --color-blue-info: #378cc9;
  --color-green: #28a745;
  --color-red: rgb(248, 25, 65);
  --color-orange: rgb(253, 102, 45);
  --color-light: #f8f9fa;
  --color-dark: #343a40;
  --color-muted: #6c757d;
  --color-white: #ffffff;
  --color-black: #000000;
}

/* remove default css of navigation links */
.unstyled-link {
  display: block;
  text-decoration: none;
  color: inherit;
}
.unstyled-link:hover {
  text-decoration: none;
}

/* Common CSS end */