.second-step-container{
    margin-top: 5%;

    padding: 2%;
}


.second-step-input-container{
    display: flex;
    flex-direction: column;
}

.second-step-input-container-top{
    display: flex;
  gap: 5%;
  margin-bottom: 5%;
  flex-wrap: wrap; 
}
.second-step-input-container-top > *:nth-child(n+5) {
    margin-top: 20px;
}
.second-step-entity-address{
    width: 40%;
}

.second-step-input-container-bottom{
    display: flex;
    gap: 3%;
    margin-bottom: 5%;
}
