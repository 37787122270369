.supplier-map-plot {
    border: 1px solid lightgray;
  }

  .map-reset-section{
    margin-bottom: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end;
    /* margin-left: auto; */
  }

  .map-reset-zoom-button{
    color: #00094b !important;
    font-weight: bold !important;
    border-color: #00094b !important;
  
  }
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  }

  .legend {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
}

.color-block {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid #000;
}

.black { background-color: #000; }
.red { background-color: red; }
.orange { background-color: orange; }
.yellow { background-color: yellow; }
.green { background-color: green; }
.learn-more {
    font-size: 14px;
    color: #333;
}
  
.section-header-text {
  font-weight: bold;
  margin-top: 1rem;
  font-size: 2rem;
}
.disclosure-group {
  margin: 20px;
}

.disclosure-header {
  cursor: pointer;
  font-weight: bold;
  margin-top: 1rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.disclosure-header .arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.disclosure-header .arrow.open {
  transform: rotate(90deg);
}

.disclosure-header .arrow.closed {
  transform: rotate(0deg);
}

.disclosure-content {
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  max-height: 0;
  opacity: 0;
}

.disclosure-content.show {
  max-height: 1000px; /* Adjust this value as needed */
  opacity: 1;
}

.disclosure-content.hide {
  max-height: 0;
  opacity: 0;
}
