.advanced-search-container{
    margin-left: 10%;
    margin-right: 10%;

    margin-top: 5%;

    margin-bottom: 5%;

    background-color: #fbfafa;

    border-radius: 2%;
    
   
 
    padding: 2%; 

}

.advanced-search-input-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.advance-search-input{
    width: 60%
}

.advance-search-row-sus{
    background-color: #f9ccc9;
}
.advance-search-result{
    margin-top: 5%;  
}

.advanced-search-heading{
    text-align: center;


}

.advance-search-item-sus{
    background-color: #f9ccc9;
}

.advance-search-results-legend{
    margin-top: 2%;
}

.advance-search-result-legend-color{
    width: 2px;
    height: 2px;
    background-color: red;
}

.advance-search-result-legend-name{
    display: inline !important;
}