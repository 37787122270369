/* CSS - Firststep.js */

.first-step-search-input-container{

    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2%;
}
    
.first-step-search-input{

    width: 60%

}



.first-steploader-container {
    display: flex;
    align-items: center;
    justify-content: center;

}



/* Mergetable.js css */



.first-step-switch-component {
    right: 10px;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-left: auto;
    background-color: #F0F0F0; 
    border-radius: 10px;
}

/* .first-step-switch-box-container{
    width: 20px;
} */
.first-step-switch-component-text {
    margin: 0;
    margin-right: 10px;
    color: #00094b;
    font-weight: bolder;
}
.select-button-group {
    display: flex;
    justify-content: space-between;
}
.file-container-style{
    margin-top: 5%;
    text-align: center;
}
.file-input-style {
    display: none;
}
.clear-button-style {
    margin-left: 10px;
}