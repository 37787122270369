.third-step-container{
    margin-top: 5%;

    padding: 2%;
}

.third-step-data-container{
display: flex;
flex-direction: row;
justify-content: space-between;
}

.third-step-merge-companies{
    width: 50%;
 
}

.third-step-target-company{
    width: 40%;
    background-color: #e8f4f8;
    padding: 2%;
    border-radius: 8px;
    
}

.third-step-target-display-container{
    display: flex;
    flex-direction: column;
   
}

.third-step-target-display-header{
    text-align: left;
    font-size: 2rem;
    font-weight: bolder;
}

.third-step-target-display-data-container{
    margin-top: 2%;
    /* display: flex;
    flex-direction: row; */
    color: #808384;
    gap: 5%;
    row-gap: 5%;
    column-gap: 10%;
    width: 100%;
    flex-wrap: wrap;
}

.third-step-target-display-data-label{
    
}

.third-step-target-display-data-value{

}

.third-step-target-display-label{
    font-weight: bold;
    margin-right: 5px;
}

.mb-2rem{
    margin-bottom: 0.8rem !important;
}