.about-chart-container {
    /* background-color: #FFF; */
    padding: 2rem;
    margin: 1rem 1rem 1rem 0;
    width: 45%;
}

.about-chart-container-header {
    font-weight: lighter;
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
}