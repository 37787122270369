.company-page-header {
    background-color: #f0f0f0;
    margin-bottom: 0px;
    padding-bottom: 0%;
}
.custom-tabs {
    border-bottom: none;
    display: inline-flex;
  }
  
  .custom-tabs .nav-item {
    margin-right: -1px; /* Negative margin to overlap borders */
  }
  
  .custom-tabs .nav-item .nav-link.custom-tab {
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 18px 18px 0 0;
    padding: 8px 16px;
    background-color: #d3d0d0;
    transition: all 0.3s ease;
    color: var(--color-blue-info) !important;
  }
  
  .custom-tabs .nav-item .nav-link.custom-tab:hover {
    background-color: var(--color-blue-info);
    color: var(--color-white) !important;
  }
  
  .custom-tabs .nav-item .nav-link.custom-tab.active {
    background-color: #f0f0f0;
    border: 2px solid #d3d0d0;
    border-bottom: 1px solid var(--color-white); 
    margin-bottom: -1px; 
    z-index: 100; 
    color: var(--color-blue-info) !important;
    font-weight: bolder;
  }
  
  /* Override Bootstrap's focus styles */
  .custom-tabs .nav-item .nav-link.custom-tab:focus {
    box-shadow: none;
  }
  
  /* Add a bottom border to the tab container */
  .custom-tabs::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ccc;
    z-index: 0;
  }