.supplier-map-plot {
    border: 1px solid lightgray;
  }

  .map-reset-section{
    margin-bottom: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end;
    /* margin-left: auto; */
  }

  .map-reset-zoom-button{
    color: #00094b !important;
    font-weight: bold !important;
    border-color: #00094b !important;
  
  }
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  }

  .legend {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
}

