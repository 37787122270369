.tableContainer {
    max-width: '80%';
    margin: 'auto';
    margin-top: '20px';
  }
.table {
    min-width: 650;
    border: '1px solid #ddd'; 
    border-radius: '5px';
    margin: 1%;
}
.requestsContainer {
    
    padding: 10px;
  }
.dataGridContainer {
    height: 600;
    width: 90%;
    margin: auto;
    margin-top: 20px;
}
.actionButton {
    background-color: 'blue';
    color: 'white';
    font-size: large;
    
}
  .actionButton:hover {
    background-color: 'green';
  }
.headerBold {
    color: black;
    font-size: large;
    font-weight: bolder;
  }