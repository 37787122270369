.supplier-modal-container{
    position: absolute;
    top: 10%;
    left: 10%;
    /* transform: translate(-50%; -50%); */
    width: 80%;
    background-color: #FFF;
    padding: 2rem;
    max-height: 100vh;
    
}

.tier-card-modal {
    width: 97%;
    margin: 2rem 1rem;
    /* background-color: #f6f6f6; */
    position: relative;
    height: auto;
    border: 1px solid #f6f6f6;
    background-color: #D9E4EC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tier-suppliers-container-modal{
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    max-height: 22rem;
}

.tier-suppliers-modal{
    background-color: #FFF;
}

.tier-supplier-table-header-modal{
    font-weight: bold;
}

.hide-scroll{
    overflow: scroll;
}

.hide-scroll::-webkit-scrollbar {
    max-height: 20%; 
}