.footer-container {
    padding: 2rem;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    /* align-items: flex-end; */
    gap: 2rem;
    /* margin-left: 4rem; */
}
.footer-image-conatiner{
    margin-bottom: 2rem;
}

.footer-container-top {
    display: flex;
    justify-content: space-between;
}

.footer-content-1-item {
    color: rgb(32, 133, 194);
    font-weight: lighter;
    /* padding: 0.2rem; */
}

.footer-link {
    text-decoration: none;
    color: rgb(32, 133, 194) !important;
}

.footer-bottom-link {
    text-decoration: none;
    color: rgb(32, 133, 194) !important;
    font-size: 0.8rem !important;
}

.font-bold {
    font-size: 1.1rem;
    font-weight: 500 !important;
}

.footer-content-1 {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.foot-content-2-left {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.footer-container-right {
    display: flex;
    font-weight: lighter;
    flex-direction: column;
    justify-content: space-between;
    /* width: 70%;  */
}

.footer-copyright {
    font-weight: lighter;
    color: rgb(32, 133, 194) !important;
}

.footer-container-left{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.footer-container-newsletter {
    display: flex;
    gap: 3rem;
    margin-top: 2rem;
}

.footer-newsletter-header {
    color: rgb(32, 133, 194);
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.footer-newsletter-form {
    display: flex;
    gap: 2rem;
    padding: 1rem 0;
}

.newsletter-underline{
    border: 1px solid rgb(32, 133, 194);
    width: 50px;
}

@media (max-width: 600px) {
    .footer-newsletter-form {
        flex-direction: column;
    }
    .foot-content-2-left {
        flex-direction: column;
    }
}